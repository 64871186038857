import React from 'react';
import Footer from '../components/Footer';
import Layout from '../components/layout';

class NotFoundPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isArticleVisible: true,
      timeout: false,
      articleTimeout: false,
      article: 'not-found'
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
  }

  componentDidMount() {
    this.timerID1 = setTimeout(() => this.timeout(), 325);
    this.timerID2 = setTimeout(() => this.articleTimeout(), 350);
  }

  componentWillUnmount() {
    clearTimeout(this.timerID);
  }

  timeout() {
    this.setState({
      timeout: !this.state.timeout
    });
  }

  articleTimeout() {
    this.setState({
      articleTimeout: !this.state.articleTimeout
    });
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <div>
          <div id="wrapper" className="reduceSize">
            <article
              id="thank-you"
              className={`${'active'} ${
                this.props.articleTimeout ? 'timeout' : ''
              }`}
            >
              <h2 className="major">NOT FOUND</h2>
              <span className="image main"></span>
              <p>You just hit a page that doesn&#39;t exist... the sadness.</p>
            </article>

            <Footer />
          </div>
          <div id="bg"></div>
        </div>
      </Layout>
    );
  }
}

export default NotFoundPage;
